<template>
    <div class="option_catalogue">
         <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div><h1 class="text-primary">Outils</h1></div>
            </vs-col>
        </vs-row>
        <br />
        <vs-row>
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Mollie Clef API</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" type="password" label="Mollie.com > Dashboard > Développeurs > Clés API" v-model="studio.mollie" />
                    </div>
                    <br/>
                    <h5>Assistance Mollie : <a href="tel:+33182886424">+33 (0) 1 82 88 64 24</a></h5>
                    <br/>
                    <vs-button v-if=" studio.mollie == ''  || studio.mollie == undefined " style="background:#1c0f2a;" type="filled">
                        <a href="https://www.mollie.com/dashboard/signup/7473411?lang=fr" style="color:white;" target="_blank">Créer un compte Mollie</a>
                    </vs-button>
                    <vs-button v-else color="success" type="filled" v-on:click="update('mollie')"> Enregistrer </vs-button>
                </vx-card>
                <br />
            </vs-col>
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Alma</h3>
                    <hr/>
                    <br/>
                    <p class="pt-5">Le paiement en plusieurs fois. Simple. Garanti.</p>
                    <br />
                    <p class="pb-5">Vos clients paient en plusieurs fois, vous êtes payé tout de suite.</p>
                    <br/>
                    <vs-button style="background:#1c0f2a;" type="filled">
                        <a href="https://getalma.eu/register" style="color:white;" target="_blank">Créer un compte Alma</a>
                    </vs-button>
                </vx-card>
                <br />
            </vs-col>
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Paramétrage des @ e-mails</h3>
                    <hr/>
                    <br/>
                    <p>Vous êtes le fier détenteur d'une application Studioconnect avec nom de domaine personnalisé? </p>
                    <p class="pt-4">Paramétrez vos e-mails sur tous vos appareils en suivant les tutoriels suivants : </p>
                    <br />
                    <vs-button style="background:#1c0f2a;" type="filled" @click="toPage('/admin/option/e-mails-externes')"> Voir la documentation </vs-button>   
                </vx-card>
                <br />
            </vs-col>
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Google Data Studio</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" type="text" label="Google Data Studio > Partager ▼ > Obtenir le lien" v-model="studio.ga" />
                    </div>
                    <br/>
                    <h5>Insérez votre lien, ou demandez-en un à Studioconnect</h5>
                    <br/>
                    <vs-button v-if=" studio.ga == '' || studio.ga == undefined " style="background:#1c0f2a;" type="filled">
                        <a href="tel:+33484510894" style="color:white;">Studioconnect : 04.84.51.08.94</a>
                    </vs-button>
                    <vs-button v-else color="success" type="filled" v-on:click="update('ga')"> Enregistrer </vs-button>
                </vx-card>
                <br />
            </vs-col>
            <!-- <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Meta ADS (Facebook & Instagram)</h3>
                    <hr/>
                    <br/>
                    <h5>Crée un accès rapide depuis la page d'Accueil</h5>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" type="text" label="Copiez le lien vers votre compte Meta Business" v-model="studio.fb" />
                    </div>
                    <br/>
                    <vs-button v-if=" studio.fb == '' || studio.fb == undefined " style="background:#1c0f2a;" type="filled">
                        <a href="https://business.facebook.com/" style="color:white;" target="_blank">Créer un compte Meta Business</a>
                    </vs-button>
                    <vs-button v-else color="success" type="filled" v-on:click="update('fb')"> Enregistrer </vs-button>
                </vx-card>
                <br />
            </vs-col> -->
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Génération de prospects</h3>
                    <hr/>
                    <br/>
                    <img src="@/assets/images/fitnessboost/fitness-boost-logo.png" alt="Logo Fitness Boost" style="background-color: black; max-height:50px; max-width: 200px;" class="px-1 pt-1 w-full">
                    <br>
                    <p class="pt-2">Pour lancer une campagne Fitness Boost veuillez contacter marketing@fitnessboost.fr.</p>
                    <br/>
                    <vs-button style="background:#1c0f2a;" type="filled">
                        <a href="https://useplink.com/payment/u0iHZR1nfFbHr7kewMk9u/" style="color:white;" target="_blank">Démarrer</a>
                    </vs-button>
                </vx-card>
                <br />
            </vs-col>
            <vs-col vs-xs="12" vs-lg="4" class="px-3">
                <vx-card>
                    <h3>Mailjet</h3>
                    <hr/>
                    <br/>
                    <div v-if=" !mailjet.setted ">
                        <p>
                            <a href="https://mailjet.com/fr/" target="_blank">Créez un compte Mailjet</a>, puis renseignez vos <a href="https://app.mailjet.com/account/apikeys" target="_blank">clés API</a>. 
                        </p>
                        <div class="vx-col w-full">
                            <vs-input class="w-full" type="text" label="Public key" v-model="mailjet.public" />
                        </div>
                        <div class="vx-col w-full">
                            <vs-input class="w-full" type="text" label="Private key" v-model="mailjet.private" />
                        </div>
                        <br/>
                        <vs-button v-if="mailjet.private && mailjet.public" @click="setMailjet()" color="success" type="filled"> Enregistrer </vs-button>
                    </div>
                    <div v-else>
                        <h5>Votre compte Mailjet est connecté !</h5>
                        <p>Pour réinitialiser vos clés, vous devez d'abord les supprimer.</p>
                        <br />
                        <vs-button color="danger" type="filled" @click="deleteMailjet()"> Supprimer mes clés </vs-button>
                        <br />
                        <br />
                        <vs-button color="success" type="filled" @click="toPage('/admin/com/member')"> Créer une campagne </vs-button>
                    </div>
                </vx-card>
                <br />
            </vs-col>
        </vs-row>
        
    </div>
</template>



<style lang="scss">

</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Studio from '@/database/models/studio'
// import Mailjet from '@/database/models/mailjet'

export default {
    data(){
        return{
            studio:{
                mollie: null,
                fb: null,
                ga: null
            },
            mailjet: {
                setted: false,
                public: null,
                private: null
            }
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
            //refresh
            Studio.getAllMemory((list_studio)=>
            {
                //find actual studio
                let studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));
                studio.mollie? this.studio.mollie = studio.mollie : ''
                studio.fb? this.studio.fb = studio.fb : ''
                studio.ga? this.studio.ga = studio.ga : ''

            })

            this.getMailjet()

        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		update(id){
            //nettoyage vide
            let data = {}
            // for( var i in this.studio)
            if( this.studio[id])
                data[id] = this.studio[id]
            // console.log(data)
            //envoi mise a jour
            Studio.update( this.$srvApi.getStudio(), data )
            .then(()=>{
                alert('Modification enregistrée')
            })
            .catch(()=>{
                alert('Une erreur est survenue !')
            })
        },
        getMailjet() {
            this.$srvApi.Req('get', 'studio/mailjetconfig')
            .then((rep)=>{
                if (rep.data.length > 0) this.mailjet.setted = rep.data[0].uid
            })
        },
        setMailjet() {
            if (this.mailjet.setted === false ) {
                if (this.mailjet.public != '' && this.mailjet.private != '' ) {
                    this.$srvApi.Req('post', 'studio/mailjetconfig',{
                        apikey_public : this.mailjet.public,
                        apikey_private : this.mailjet.private,
                    })
                    .then(function() {
                        autoRefresh.refresh()
                    })
                }

            }
        },
        deleteMailjet() {
            if(!confirm("Supprimer les clés API Mailjet?"))
                return;
            
            this.$srvApi.Req('delete', 'studio/mailjetconfig/'+this.mailjet.setted)
            .then(()=>{
                this.mailjet = {
                    setted: false,
                    public: null,
                    private: null
                }
                autoRefresh.refresh()
            });
        },
        toPage( url )
		{
			this.$router.push(url).catch(() => {});
		}
    }
};

</script>